/* import font VG5000-Regular.otf */
@font-face {
  font-family: "VG5000";
  src: url("./fonts/VG5000-Regular.otf");
}

@font-face {
  font-family: "BigelowRules";
  src: url("./fonts/BigelowRules-Regular.ttf");
}

@font-face {
  font-family: "Ubuntu";
  src: url("./fonts/Ubuntu-Regular.ttf");
}

img {
  pointer-events: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-black {
  background-color: #090909 !important;
}

.navbar {
  height: 100px;
  background-color: #090909 !important;
}

.nav-item {
  padding: 0 20px;
  /* About */
  font-family: "VG5000";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.hero-section {
  background-color: #090909;
  height: calc(100vh);
  overflow: auto;
  background-image: url("./images/bg-1.svg");
  background-size: cover;
  position: relative;
}

.hero-section .hero-text {
  /* BigelowRules-Regular.ttf */
  font-family: "BigelowRules";
  font-style: normal;
  font-weight: 400;
  font-size: 112px;
  line-height: 90%;
  text-transform: capitalize;
  color: #ffffff;
  border: 2px solid #eaeaea;

  fill: #fff;
  stroke-width: 2px;
  stroke: #eaeaea;
  box-shadow: 4px 4px 8px 0px #5c5c5c inset;
}

.hero-desc {
  font-family: "Ubuntu";
  color: #939393;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
}

.container {
  height: calc(100vh - 100px);
}

.text-animation {
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #090909;
  z-index: 1;
  position: absolute;
  bottom: 0;
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-left: 0;
  border-right: 0;
}

.text-animation .text-item {
  color: rgba(255, 255, 255, 0.65);
  font-family: Ubuntu;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.scroll {
  position: relative;
  width: 100vw;
  overflow: hidden;
  z-index: 1;
  margin: 0;
  padding: 0;
}

.m-scroll {
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
  animation: scrollText 10s infinite linear;
  margin: 0;
  font-size: 0;
  display: flex;
  justify-content: space-between;
  width: fit-content;
}

.text-item {
  display: flex;
  align-items: center;
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.section-container {
  margin: 42px 100px 42px 100px;
  background-image: url("./images/bg-2.svg");
  background-size: cover;
  border: 4px solid rgba(255, 255, 255, 0.5);
  overflow: hidden;
}

.footer {
  height: 80px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #939393;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hr {
  border: 1px solid #eaeaea;
  margin: 0 100px 0 100px;
}

.section-secondary-text {
  /* Token Supply */
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 90%;
  /* identical to box height, or 36px */
  text-transform: capitalize;
  color: #939393;
}

.contact-item {
  /* Telegram Channel */
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 28px */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.06em;

  color: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.link {
  text-decoration: none;
}

.nav-item a {
  color: #fff;
  font-family: "BigelowRules";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 40px;
  padding-right: 40px;
}

/* MOBILE SECTION */

.mobile .nav-item a {
  padding-left: 0px;
  padding-right: 0px;
}

.mobile body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mobile .bg-black {
  background-color: #090909 !important;
}

.mobile .navbar {
  height: 100px;
  background-color: #090909 !important;
}

.mobile .nav-item {
  padding: 0 10px; /* Adjusted padding for mobile */
  /* About */
  font-family: "VG5000";
  font-style: normal;
  font-weight: 400;
  font-size: 18px; /* Adjusted font size for mobile */
  line-height: 22px; /* Adjusted line height for mobile */
  /* identical to box height */

  color: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.mobile .hero-section {
  background-color: #090909;
  /* height: calc(100vh); */
  overflow: hidden;
  background-image: url("./images/bg-1.svg");
  background-size: cover;
  position: relative;
}

.mobile .hero-section .hero-text {
  /* BigelowRules-Regular.ttf */
  font-family: "BigelowRules";
  font-style: normal;
  font-weight: 400;
  font-size: 48px; /* Adjusted font size for mobile */
  line-height: 90%;
  text-transform: capitalize;
  color: #ffffff;
  border: 2px solid #eaeaea;

  fill: #fff;
  stroke-width: 2px;
  stroke: #eaeaea;
  box-shadow: 4px 4px 8px 0px #5c5c5c inset;
}

.mobile .hero-desc {
  font-family: "Ubuntu";
  color: #939393;
  font-size: 16px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */ /* Adjusted line height for mobile */
}

.mobile .container {
  height: 100%;
}

.mobile .text-animation {
  height: 32px; /* Adjusted height for mobile */
  display: flex;
  align-items: center;
  background-color: #090909;
  z-index: 1;
  position: absolute;
  bottom: 0;
  border: 2px solid rgba(255, 255, 255, 0.5); /* Adjusted border width for mobile */
  border-left: 0;
  border-right: 0;
}

.mobile .text-animation .text-item {
  color: rgba(255, 255, 255, 0.65);
  font-family: Ubuntu;
  font-size: 14px; /* Adjusted font size for mobile */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.mobile .scroll {
  position: relative;
  width: 100vw;
  overflow: hidden;
  z-index: 1;
  margin: 0;
  padding: 0;
}

.mobile .m-scroll {
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
  animation: scrollText 10s infinite linear;
  margin: 0;
  font-size: 0;
  display: flex;
  justify-content: space-between;
  width: fit-content;
}

.mobile .text-item {
  display: flex;
  align-items: center;
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.mobile .section-container {
  margin: 21px 30px; /* Adjusted margin for mobile */
  background-image: url("./images/bg-2.svg");
  background-size: cover;
  border: 2px solid rgba(255, 255, 255, 0.5); /* Adjusted border width for mobile */
  overflow: hidden;
}

.mobile .footer {
  height: 60px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px; /* Adjusted font size for mobile */
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #939393;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile .hr {
  border: 1px solid #eaeaea;
  margin: 0 30px; /* Adjusted margin for mobile */
}

.mobile .section-secondary-text {
  /* Token Supply */
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 24px; /* Adjusted font size for mobile */
  line-height: 90%;
  /* identical to box height, or 36px */
  text-transform: capitalize;
  color: #939393;
}

.mobile .contact-item {
  /* Telegram Channel */
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px; /* Adjusted font size for mobile */
  line-height: 140%;
  /* identical to box height, or 28px */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.06em;
  color: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.mobile .link {
  text-decoration: none;
}
